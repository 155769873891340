<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class TemporaryHomepage extends Vue {

}
</script>

<template>
  <div class="hero-section">
    <div class="wrap">
      <div class="content">
        <p class="header">Pr. d. 1/11-2024 har Jyske Finans A/S overtaget Opendos privatleasing- og fleetmanagementportefølje.</p>
        <p>Det betyder, at engagementet bliver overtaget af Jyske Finans.</p>
        <p>Udover engagementet, flytter flere medarbejdere fra Opendo også med over til Jyske Finans. De skal være med
          til at sikre en gnidningsfri overgang for kunderne.</p>
        <p>Er du privatleasingkunde, kan du finde svar på de mest relevante spørgsmål <a href="https://www.privatleasing.dk/opendo/"><b>her</b></a>.</p>
        <p>Er du erhvervs-/fleet kunde, kan du finde svar på de mest relevante spørgsmål <a href="https://jyskefinans.dk/opendo/"><b>her</b></a>.</p>
        <p>Jyske Finans glæder sig meget til at byde dig velkommen som kunde.</p>

        <p><br><b>Opendo Partner Service</b></p>
        <p>Hvis du skal I kontakt med Opendo Partner Service, skal du henvende dig på tlf: 7071 6312 eller på mail: <a href="mailto:leasing@opendo.dk">leasing@opendo.dk</a>.</p>

        <p>Partner Service har åbent mandag til fredag fra kl. 09-16.</p>

        <p>Hvis du skal tilgå Banklogin, skal du trykke på "Log på" oppe i højre hjørne.</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header {
  font-size: 24px;
  font-weight: bold;
  line-height: 146px;
}
.hero-section {
  background: #e8f5f4;
  border-radius: 40px;
  padding-bottom: 170px;
  text-align: center;
  padding-top: 80px;
}
@media only screen and (min-width : 1600px) {
  .hero-section {
    height: calc(100vh - 100px);
  }
}
.content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #4c6c69;
  margin-top: 40px;
  padding-bottom: 40px;
}
.content > p {
  margin-bottom: 15px;
  line-height: 24px;
}
.content a {
  color: #4c6c69;
}
.wrap {
  margin-left: auto;
  margin-right: auto;
}
</style>