<template>
  <div class="fade-in-root" ref="fadeRoot">
      <div class="wrapper">
        <header-banner v-bind:cmsPageData="cmsPageData"  v-bind:cmsGlobalData="cmsGlobalData" />
        <component-header v-bind:cmsPageData="cmsPageData"  v-bind:cmsGlobalData="cmsGlobalData" />
        <div class="header-spacer" :style="'paddingTop:'+bannerHeight+'px'"></div>
        <router-view  v-bind:cmsPageData="cmsPageData"  v-bind:cmsGlobalData="cmsGlobalData" />
      </div>
<!--      <component-footer v-bind:cmsPageData="cmsPageData"  v-bind:cmsGlobalData="cmsGlobalData" />-->
      <component-ask-popup v-bind:cmsPageData="cmsPageData"  v-bind:cmsGlobalData="cmsGlobalData"  />
<!--      <newsletter-button/>-->
  </div>
</template>



<script>
// import footerData from "@/data/footer-data.json";
// import RemoteData from "@/remoteData";
import eventBus from '../../eventBus';

export default {
  data() {
    return {
      cmsPageData: null,
      cmsGlobalData: null,
      bannerHeight: 0,

      seo: {
        meta_title:  null,
        meta_description:  null,
        meta_image:  null,
        meta_keywords:  null
      },
      seoGlobal: {
        meta_title:  "Opendo",
        meta_description:  "Opendo",
        meta_image:  "https://www.opendo.dk/wp-api/wp-content/uploads/2022/05/IMG_4474-2-768x1024.jpeg",
        meta_keywords:  "opendo"
      }
    }
  },

  beforeDestroy(){

  },
  created(){
    var th = this;
    eventBus.$on("bannerHeight", function(e){
      th.bannerHeight = e;
    });
    eventBus.$on("handlePageAnimations", function(e){
      th.handlePageAnimations();
    });
  },
  mounted(){

    var th = this;

    // var sessionPageData = sessionStorage.getItem("cmsPageData"+window.location.pathname);
    // if (sessionPageData){
    //   console.log(sessionPageData);
    //   th.cmsPageData = sessionPageData;
    // }

    window.addEventListener("loaded-cms-global-data", function(){
      th.cmsGlobalData = window.cmsGlobalData;
      th.saveCache("cmsGlobalData", th.cmsGlobalData);
      eventBus.$emit("cmsGlobalDataSet");
      th.prepareCMSGlobalData();
		});
    if (window.cmsGlobalData){
      th.cmsGlobalData = window.cmsGlobalData;
      th.saveCache("cmsGlobalData", th.cmsGlobalData);
      eventBus.$emit("cmsGlobalDataSet");
      th.prepareCMSGlobalData();
    }


    window.addEventListener("loaded-cms-page-data", function(){
      th.prepareCMSPageData();
      // th.$nextTick(function(){
        th.$refs.fadeRoot.classList.add("visible");
        // th.clearPageAnimations();
        th.handlePageAnimations();
      // });
		});
    if (window.cmsPageData){
      th.prepareCMSPageData();
      // th.$nextTick(function(){
        th.$refs.fadeRoot.classList.add("visible");
        // th.clearPageAnimations();
        th.handlePageAnimations();
      // });
    }

    this.$watch(
      () => this.$route.name,
      (toParams, previousParams) => {
        var d = th.loadCache("cmsPageData"+window.location.pathname);
        if (d){
          this.cmsPageData = d;
        }
        d = th.loadCache("cmsGlobalData");
        if (d){
          this.cmsGlobalData = d;
        }
        // this.clearPageAnimations();
        this.handlePageAnimations();
      }
    );

    this.$watch(
      () => this.$route.query,
      (toParams, previousParams) => {
        var th = this;
        th.handlePageAnimations();
      }
    )

    

    

    

    // var th = this;
    // RemoteData.getAllData().then((data) => {
    //   th.pageData = data;
    //   // console.log(data);
    // });
    
    // console.log(remoteData.getAllData());
  },
  methods:{
    clearPageAnimations: function(){
      const views = document.querySelectorAll('.op-fade-in-animation');
      for(var i=0; i<views.length; i++){
        views[i].classList.remove('op-fade-in-animation');
      }
    },
    handlePageAnimations: function(){
      
      

      const observer = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
          // If the element is visible
          if (entry.isIntersecting) {
            // Add the animation class
            entry.target.classList.add('op-fade-in-animation');
          }
        });
      });

      const viewbox = document.querySelectorAll('.fade-on-enter');
      viewbox.forEach(image => {
        observer.observe(image);
      });
    }, 
    prepareCMSGlobalData: function(){
      var meta_title = null;
      var meta_description = null;
      var meta_image = null;
      var meta_keywords = null;

      if (this.cmsGlobalData.seo){
        meta_title = this.cmsGlobalData.seo.meta_title;
        meta_description = this.cmsGlobalData.seo.meta_description;
        meta_image = this.cmsGlobalData.seo.meta_image;
        meta_keywords = this.cmsGlobalData.seo.meta_keywords;
      }

      if (meta_title){
        this.seoGlobal.meta_title = meta_title;
      }
      if (meta_description){
        this.seoGlobal.meta_description = meta_description;
      }
      if (meta_image){
        this.seoGlobal.meta_image = meta_image;
      }
      if (meta_keywords){
        this.seoGlobal.meta_keywords = meta_keywords;
      }
      
    },
    prepareCMSPageData: function(){
      this.cmsPageData = window.cmsPageData;


      // console.log("<SEO>");
      // console.log(this.cmsPageData.page.seo);
      

      var meta_title = null;
      var meta_description = null;
      var meta_image = null;
      var meta_keywords = null;

      if (this.cmsPageData.page.seo){
        meta_title = this.cmsPageData.page.seo.meta_title;
        meta_description = this.cmsPageData.page.seo.meta_description;
        meta_image = this.cmsPageData.page.seo.meta_image;
        meta_keywords = this.cmsPageData.page.seo.meta_keywords;
      }

      if (meta_title){
        this.seo.meta_title = meta_title;
      }
      if (meta_description){
        this.seo.meta_description = meta_description;
      }
      if (meta_image){
        this.seo.meta_image = meta_image;
      }
      if (meta_keywords){
        this.seo.meta_keywords = meta_keywords;
      }
      

      // if (meta_image && meta_image.sizes && meta_image.sizes.large){
      //   meta_image = meta_image.sizes.large;
      // }else if (meta_image && meta_image.url){
      //   meta_image = meta_image.url;
      // }



      if (!meta_title){
        meta_title = this.seoGlobal.meta_title;
      }
      if (!meta_description){
        meta_description = this.seoGlobal.meta_description;
      }
      if (!meta_image){
        meta_image = this.seoGlobal.meta_image;
      }
      if (!meta_keywords){
        meta_keywords = this.seoGlobal.meta_keywords;
      }

      document.title = meta_title;


      document.querySelector('meta[name="description"]').setAttribute("content", meta_description);
      document.querySelector('meta[name="keywords"]').setAttribute("content", meta_keywords);
      document.querySelector('meta[property="og:description"]').setAttribute("content", meta_description);
      document.querySelector('meta[name="twitter:description"]').setAttribute("content", meta_description);
      
      document.querySelector('meta[name="image"]').setAttribute("content", meta_image);
      document.querySelector('meta[property="og:image"]').setAttribute("content", meta_image);
      document.querySelector('meta[name="twitter:image"]').setAttribute("content", meta_image);

      document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);


      // document.querySelector("title").innerHtml = "a";
      // console.log("</SEO>");
      

      this.saveCache("cmsPageData"+window.location.pathname, this.cmsPageData);
    },
    saveCache: function(key, data){
      sessionStorage.setItem(key, JSON.stringify(data));
    },
    loadCache: function(key){
      var sessionPageData = sessionStorage.getItem(key);
      if (sessionPageData){
        return JSON.parse(sessionPageData);
      }
      return null;
    }
  }
}
</script>
